import * as React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import Layout from "../components/layout";
import Seo from "../components/seo";
import {
  Section,
  Container,
  SectionTitle,
  ListPointer,
  ListItemPointer,
  BreadCrumb,
} from "../components/Section";
import {
  HeroBanner,
  HeroTitle,
  HeroCaption,
  HeroContent,
} from "../components/HeroBanner";

const HeroBannerCustom = styled(HeroBanner)`
  &:before {
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
  }

  .banner-caption {
    padding: 100px 0 60px 0;
    @media (min-width: 768px) {
      padding: 100px 0 80px 0;
    }
    @media (min-width: 992px) {
      padding: 100px 0;
    }
    @media (min-width: 1200px) {
      padding: 160px 0;
    }
  }
`;

const SitemapGrid = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
`;

const SitemapItem = styled.div`
  position: relative;
  width: 100%;
  padding: 0 15px;
  margin-bottom: 30px;
  @media (min-width: 576px) {
    flex: 0 0 50%;
    max-width: 50%;
  }
  @media (min-width: 992px) {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  & a {
    display: inline-block;
    line-height: 30px;
    color: rgba(0, 0, 0, 0.7);
    &:hover {
      color: #161420;
    }
  }
`;
const SiteMapTitle = styled(SectionTitle)`
  & a {
    color: #161420;
    &:hover {
      color: #fc0002;
    }
  }
`;
const SiteMapPage = ({ location }) => (
  <Layout location={location}>
    <Seo
      title="Sitemap - Panhandle Metals"
      description="An overview of all the website pages we provide for you to navigate at Panhandle Metals."
      location={location}
    />
    <Section
      bgColor="#fff"
      bgBefore="#002765"
      bgBeforeHeight="100%"
      bgBeforeWidth="100%"
      className="section-sitemap"
    >
      <HeroBannerCustom
        className="banner-hero"
        bgBefore="block"
        data-shadowtext="Sitemap"
      >
        <HeroCaption className="banner-caption">
          <Container maxWidth="1640px">
            <BreadCrumb>
              <Link to="/">Home</Link>
              <span>Sitemap </span>
            </BreadCrumb>
            <HeroContent>
              <HeroTitle className="m-auto w-auto">Sitemap </HeroTitle>
            </HeroContent>
          </Container>
        </HeroCaption>
      </HeroBannerCustom>
    </Section>

    <Section
      ept="120px"
      epb="120px"
      xpt="80px"
      xpb="80px"
      pt="40px"
      pb="40px"
      bgColor="#fff"
      className="section-url"
    >
      <Container>
        <SitemapGrid>
          <SitemapItem>
            <SiteMapTitle className="h4">TX Building Types</SiteMapTitle>
            <ListPointer>
              <ListItemPointer><Link to="/tx-metal-buildings">TX Metal Buildings</Link></ListItemPointer>
              <ListItemPointer><Link to="/tx-carports">TX Carports</Link></ListItemPointer>
              <ListItemPointer><Link to="/tx-garages">TX Garages</Link></ListItemPointer>
              <ListItemPointer><Link to="/tx-barns">TX Barns</Link></ListItemPointer>
              <ListItemPointer><Link to="/tx-rv-covers">TX RV Covers</Link></ListItemPointer>
              <ListItemPointer><Link to="/tx-utility-buildings">TX Utility Buildings</Link></ListItemPointer>
              <ListItemPointer><Link to="/tx-loafing-sheds">TX Loafing Sheds</Link></ListItemPointer>
              <ListItemPointer><Link to="/tx-metal-sheds">TX Metal Sheds</Link></ListItemPointer>
              <ListItemPointer><Link to="/tx-commercial-buildings">TX Commercial Buildings</Link></ListItemPointer>
              <ListItemPointer><Link to="/tx-storage-buildings">TX Storage Buildings</Link></ListItemPointer>
              <ListItemPointer><Link to="/tx-workshop-buildings">TX Workshop Buildings</Link></ListItemPointer>
              <ListItemPointer><Link to="/tx-metal-warehouses">TX Metal Warehouses</Link></ListItemPointer>
            </ListPointer>
          </SitemapItem>
          <SitemapItem>
            <SiteMapTitle className="h4">Quick Links</SiteMapTitle>
            <ListPointer>
              <ListItemPointer><Link to="/rto">RTO</Link></ListItemPointer>
              <ListItemPointer><Link to="/financing">Financing</Link></ListItemPointer>
              <ListItemPointer><Link to="/about-us">About Us</Link></ListItemPointer>
              <ListItemPointer><Link to="/reviews">Reviews</Link></ListItemPointer>
              <ListItemPointer><Link to="/contact-us">Contact Us</Link></ListItemPointer>
              <ListItemPointer><Link to="/become-dealer">Become a Dealer</Link></ListItemPointer>
              <ListItemPointer><a href="https://panhandlemetals.sensei3d.com" target="_blank" rel="noreferrer" aria-label="Design in 3D">Design in 3D</a></ListItemPointer>
              <ListItemPointer><Link to="/privacy-policy">Privacy Policy</Link></ListItemPointer>
              <ListItemPointer><Link to="/terms-and-conditions">Terms & Conditions</Link></ListItemPointer>
              <ListItemPointer><Link to="/shopping-policy">Shopping Policy</Link></ListItemPointer>
              <ListItemPointer><Link to="/pricing-disclaimer">Pricing Disclaimer</Link></ListItemPointer>
              <ListItemPointer><Link to="/cart">Cart</Link></ListItemPointer>
              <ListItemPointer><Link to="/checkout">Checkout</Link></ListItemPointer>
            </ListPointer>
          </SitemapItem>
          <SitemapItem>
            <SiteMapTitle className="h4">Know-How</SiteMapTitle>
            <ListPointer>
              <ListItemPointer><Link to="/faq">FAQs</Link></ListItemPointer>
              <ListItemPointer><Link to="/ordering-process">Ordering Process</Link></ListItemPointer>
              <ListItemPointer><Link to="/site-preparation">Site Preparation & Installation{" "}</Link></ListItemPointer>
              <ListItemPointer><Link to="/roofstyle">Roof Styles</Link></ListItemPointer>
              <ListItemPointer><Link to="/color-options">Color Options</Link></ListItemPointer>
              <ListItemPointer><Link to="/coverage-lead-times">Coverage Area & Lead Times</Link></ListItemPointer>
              <ListItemPointer><Link to="/gallery">Gallery</Link></ListItemPointer>
              <ListItemPointer><a href="/pdf/Panhandle-Brochure_Price-Sheets.pdf" target="_blank" rel="noreferrer" aria-label="TBS Brochure | Price Sheets" data-title="TBS Brochure | Price Sheet">Brochure &amp; Price Sheets</a></ListItemPointer>
              <ListItemPointer><Link to="/sitemap">Sitemap</Link></ListItemPointer>
            </ListPointer>
          </SitemapItem>          
        </SitemapGrid>
      </Container>
    </Section>
  </Layout>
);

export default SiteMapPage;
